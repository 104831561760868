@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;1,300;1,400&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;1,100;1,400;1,600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,600;0,700;0,800;0,900;1,300;1,500;1,600;1,700;1,800;1,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
  height: 100%;
  min-height: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden; 
  background-color: #0d0c0b;
  background: url(/static/media/background-final-smaller-leaves-2.c4e686ad.png);
  background-position:center;
  background-size: cover; 
}

@media only screen and (max-width: 600px) {
  .App {
    background: url(/static/media/background-final-smaller-leaves-2-phone.58995cb2.png);
    background-position:center;
    background-size: cover; 
  }
}

.App-nav {
  height: 8vmin;
}

.App-body {
  min-height: 0;
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
